/* global avignon_values */
import autosize from 'autosize'

$.fn.PopulatedFieldChecked = function() {
  let self = this

  self.check = function() {
    if (self.val()) {
      self.closest('.field').addClass('field--populated')
    } else {
      self.closest('.field').removeClass('field--populated')
    }
  }

  return function() {
    self.on('change keydown keyup', self.check)
    self.check()
    return self
  }()
}

$.fn.ContactForm = function() {
  let self = this

  if (self.data('contactForm')) {
    return self.data('contactForm')
  }

  self.fields = {
    action: self.find('input[name="action"]'),
    nom: self.find('input[name="nom"]'),
    courriel: self.find('input[name="courriel"]'),
    message: self.find('textarea[name="message"]'),
  }
  self.el = {
    submit: self.find('button[type="submit"]'),
    wrapper: self.closest('.footer-wrapper'),
    response: self.find('.contact-form__response'),
  }
  self.action = self.fields.action.val()

  self.get_form_data = function() {
    let data = {}
    for (let field in self.fields) {
      data[field] = self.fields[field].val()
    }
    return data
  }

  self.handle_submit = function(e) {
    e.preventDefault()
    self.el.submit.prop('disabled', true)
    let data = self.get_form_data()
    $.post(avignon_values.ajax_url, data, self.handle_response, 'json')
    self.el.wrapper.addClass('--waiting')
    self.el.response.removeClass('text-danger').text('')
  }

  self.handle_response = function(response) {
    self.el.submit.prop('disabled', false)
    self.el.wrapper.removeClass('--waiting')
    if (response.success) {
      self.handle_success(response)
    } else {
      self.handle_error(response)
    }
  }

  self.handle_error = function(response) {
    self.el.response.addClass('text-danger').text(response.error)
  }

  self.handle_success = function(response) {
    self.el.submit.prop('disabled', false)
    self.el.response.text(response.message)
    self.find('.field').removeClass('field--populated')
    self.fields.nom.val('')
    self.fields.courriel.val('')
    self.fields.message.val('')
  }

  return function() {
    if (!self.el.wrapper.length) {
      self.el.wrapper = self.closest('.menu-wrapper')
    }
    self.on('submit', self.handle_submit)
    self.find('input[type="text"], input[type="email"], textarea').each(function() {
      $(this).PopulatedFieldChecked()
    })
    self.find('textarea').each(function() {
      autosize($(this))
    })
    self.data('contactForm', self)
    return self
  }()
}

export default {
  init() {
    $('.contact-form').each(function() {
      $(this).ContactForm()
    })
  },
}
