import Burger from 'mlmi-elements/modules/burger'

export default {
  init() {
    /*
    * Burger & menu
    */
    let scrollTopBefore = 0, windowHeight = window.innerHeight, burgerTimeout = undefined
    const burger = new Burger('.burger:not(.burger--close)', {
      onToggle: function() {
        clearTimeout(burgerTimeout)
        if (burger.isExpanded) {
          $('body').addClass('nav-open')
          windowHeight = window.innerHeight
          burgerTimeout = setTimeout(function() {
            scrollTopBefore = $(window).scrollTop()
            $('.app').css({ height: windowHeight + 'px', overflow: 'hidden' })
            $('body').addClass('nav-overflow')
          }, 350)
        } else {
          $('.app').css({ height: '', overflow: '' })
          $('body, html').removeClass('nav-open nav-overflow').scrollTop(scrollTopBefore)
        }
      },
    })
    $('.burger.burger--close, .front-page .nav__brand > a').on('click', function(e) {
      e.preventDefault()
      $('.burger:not(.burger--close)').click()
    })

    /*
    * Mobile menu
    */
    let maxHeightTimeout = undefined
    $('.main-menu__title').on('click', function() {
      if ($(this).attr('data-mobile-expanded') == 'true') {
        clearTimeout(maxHeightTimeout)
        $(this).next('.main-menu__items').css('max-height', '')
        $(this).attr('data-mobile-expanded', 'false')
      } else {
        clearTimeout(maxHeightTimeout)
        let targetItem = $(this).next('.main-menu__items'), targetHeight = 0
        targetItem.find('.main-menu__item').each(function() {
          targetHeight += $(this).outerHeight(true)
        })
        targetItem.css('max-height', targetHeight + 'px')
        $(this).attr('data-mobile-expanded', 'true')
        maxHeightTimeout = setTimeout(function() {
          targetItem.css('max-height', '')
        }, 300)
      }
    })
  },
}
