/* global SC */

$.fn.Episode = function() {
  let self = this
  self.player = SC.Widget(self.find('iframe').get(0))
  self.el = {
    play: self.find('button[name="play"]'),
    pause: self.find('button[name="pause"]'),
    thumb: self.find('.episode__progress-dot'),
  }

  self.play = function() {
    if (!self.el.pause.hasClass('visible')) {
      self.player.play()
    }
    self.el.play.removeClass('visible')
    self.el.pause.addClass('visible')
  }

  self.pause = function() {
    if (!self.el.play.hasClass('visible')) {
      self.player.pause()
    }
    self.el.pause.removeClass('visible')
    self.el.play.addClass('visible')
  }

  self.progress = function(e) {
    self.el.thumb.css('left', (e.relativePosition * 100) + '%')
  }

  self.finish = function() {
    self.el.pause.removeClass('visible')
    self.el.play.addClass('visible')
  }

  return function() {
    self.el.play.on('click', self.play)
    self.el.pause.on('click', self.pause)
    self.player.bind(SC.Widget.Events.PLAY_PROGRESS, self.progress)
    self.player.bind(SC.Widget.Events.FINISH, self.finish)
    self.player.bind(SC.Widget.Events.PAUSE, self.pause)
    self.player.bind(SC.Widget.Events.PLAY, self.play)
    return self
  }()
}

export default {
  init() {
    $('.episode').each(function() {
      $(this).Episode()
    })
  },
}
