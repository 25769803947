import '@fancyapps/fancybox'
import objectFitVideos from 'object-fit-videos'
import Mobile from 'mlmi-elements/modules/mobile'
import MainMenu from './modules/main-menu'
import Form from './modules/form'
import Grid from './modules/grid'
import Gallery from './modules/gallery'
import Episode from './modules/episode'
import PeoplePlaces from './modules/people-places'
import Illustrateurs from './modules/illustrateurs'

$(function() {
  $.mlmi = {
    mobile: new Mobile(),
    iOS: navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform),
    android: /(android)/i.test(navigator.userAgent),
  }

  /* Prevent empty anchor clicks */
  $('a[href="#"]').on("click", function() {
    return false
  })

  /* Touch detection */
  $(document).on('touchstart', function() {
    $('body').removeClass('no-touch')
  })

  /* Initialize modules */
  MainMenu.init()
  Form.init()
  Grid.init()
  Gallery.init()
  Episode.init()
  PeoplePlaces.init()
  Illustrateurs.init()

  /* Video background */
  if (($.mlmi.iOS || $.mlmi.android) && $.mlmi.mobile.isMobile) {
    $('.video-fallback').removeClass('video-fallback')
    $('.wp-video-shortcode').attr('src', '')
    $('.wp-video').remove()
  } else {
    objectFitVideos()
    $('.wp-video').each(function() {
      let video_container = $(this), video = video_container.find('video').get(0)
      if (video.paused || video.currentTime == 0) {
        video_container.addClass('wp-video--active')
      } else {
        $('.wp-video video').on('play', function() {
          video_container.addClass('wp-video--active')
        })
      }
    })
  }
})
