import Carousel from './carousel'

$.fn.Grid = function() {
  let self = this
  self.title = undefined

  return function() {
    let carousel = new Carousel(self, {
      slidesPerView: 1,
      spaceBetween: 12,
      simulateTouch: true,
      loop: false,
    }, {
      mobile: true,
      desktop: false,
      wrapper: self.find('.grid__wrapper'),
      slides: self.find('.grid__item'),
      onInit: function() {
        self.title = self.find('.grid__item.grid__item--large').detach()
      },
      onKill: function() {
        self.find('.grid__wrapper').prepend(self.title)
      },
    })
    carousel.init()
    return self
  }()
}

export default {
  init() {
    $('.grid').each(function() {
      $(this).Grid()
    })
  },
}
