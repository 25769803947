import Swiper from 'swiper'
import Mobile from 'mlmi-elements/modules/mobile'

export default function(element, swiper_options, options) {
  let self = this
  self.swiper = undefined

  options = $.extend({
    mobile: false,
    desktop: false,
    wrapper: undefined,
    slides: undefined,
    onInit: undefined,
    onKill: undefined,
  }, options)

  self.initialize = function() {
    options.wrapper.addClass('swiper-wrapper')
    options.slides.addClass('swiper-slide')
    self.swiper = new Swiper(element, swiper_options)
    if (options.onInit != undefined) {
      options.onInit(self)
    }
  }

  self.kill = function() {
    options.wrapper.removeClass('swiper-wrapper')
    options.slides.removeClass('swiper-slide')
    self.swiper.destroy()
    self.swiper = undefined
    if (options.onKill != undefined) {
      options.onKill(self)
    }
  }

  self.toggle_mobile = function() {
    if (self.swiper == undefined && options.mobile) {
      self.initialize()
    } else if (self.swiper != undefined && !options.mobile) {
      self.kill()
    }
  }

  self.toggle_desktop = function() {
    if (self.swiper == undefined && options.desktop) {
      self.initialize()
    } else if (self.swiper != undefined && !options.desktop) {
      self.kill()
    }
  }

  self.init = function() {
    let mobileChecker = new Mobile()
    mobileChecker.addCallbacks(self.toggle_mobile, self.toggle_desktop)
  }
  return self
}
