import Swiper from 'swiper'
import Mobile from 'mlmi-elements/modules/mobile'

$.fn.PeoplePlaces = function() {
  let self = this
  self.top_slides = self.find('.swiper-slide')
  self.top_swiper = undefined
  self.bottom_swiper = undefined
  self.is_changing_manually = false
  self.mobileChecker = new Mobile()

  self.init_top = function() {
    let initialSlide = 0
    self.top_slides.each(function(index, slide) {
      if ('#' + $(slide).data('name') == window.location.hash) {
        initialSlide = index
      }
    })
    self.top_swiper = new Swiper(self, {
      slidesPerView: 1,
      spaceBetween: 12,
      speed: 300,
      centeredSlides: true,
      autoHeight: true,
      breakpoints: {
        768: {
          slidesPerView: 'auto',
          spaceBetween: 0,
          speed: 450,
        },
      },
      simulateTouch: true,
      threshold: 15,
      resistanceRatio: 0.25,
      roundLengths: true,
      loop: true,
      slideToClickedSlide: true,
      initialSlide: initialSlide,
      init: false,
      navigation: {
        nextEl: '.people__navigation__next',
        prevEl: '.people__navigation__previous',
      },
      on: {
        init: self.init_bottom,
        slideChange: self.init_bottom,
      },
    })
    self.top_swiper.init()
  }

  self.init_bottom = function() {
    let activeSlide = self.top_slides[self.top_swiper.realIndex]
    self.destroy_mobile_slider()
    $('.places__container .places').empty().append($(activeSlide).find('.place').clone())
    if (self.mobileChecker.isMobile) {
      self.toggle_mobile()
    }
    self.is_changing_manually = true
    window.location.hash = $(activeSlide).data('name')
    self.is_changing_manually = false
  }

  self.toggle_mobile = function() {
    if (!$('.places__container .place').length) {
      return
    }
    if (self.bottom_swiper == undefined) {
      $('.places__container .places').addClass('swiper-wrapper')
      $('.places__container .places__item').addClass('swiper-slide')
      self.bottom_swiper = new Swiper($('.places__container').get(0), {
        slidesPerView: 1,
        spaceBetween: 12,
        loop: true,
        autoHeight: false,
      })
    }
  }

  self.toggle_desktop = function() {
    if (!$('.places__container .place').length) {
      return
    }
    self.destroy_mobile_slider()
  }

  self.destroy_mobile_slider = function() {
    if (self.bottom_swiper != undefined) {
      $('.places__container .places').removeClass('swiper-wrapper')
      $('.places__container .places__item').removeClass('swiper-slide')
      self.bottom_swiper.destroy(true, true)
      self.bottom_swiper = undefined
    }
  }

  self.back_button = function() {
    if (self.is_changing_manually) {
      return
    }
    let targetSlide = 0
    self.top_slides.each(function(index, slide) {
      if ('#' + $(slide).data('name') == window.location.hash) {
        targetSlide = index
      }
    })
    self.top_swiper.slideToLoop(targetSlide)
  }

  return function() {
    self.init_top()
    self.mobileChecker.addCallbacks(self.toggle_mobile, self.toggle_desktop)
    $(window).on('popstate', self.back_button)
    return self
  }()
}

export default {
  init() {
    $('.people').each(function() {
      $(this).PeoplePlaces()
    })
  },
}
