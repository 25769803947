import Swiper from 'swiper'
import Carousel from './carousel'

$.fn.CollectionTabs = function() {
  let self = this
  self.tabs = self.find('.collection-tab')
  self.panels = $('.mobile-swiper-container.illustrations')
  self.current_tab = self.find('.collection-tab[aria-selected="true"]')
  self.current_hash = self.current_tab.attr('aria-controls')
  self.current_panel = self.panels.find('#' + self.current_hash)
  self.carousel = undefined

  self.tab_clicked = function(e) {
    e.preventDefault()
    if (self.current_tab != $(this)) {
      self.current_tab = $(this)
      self.current_hash = $(this).attr('aria-controls')
      self.current_panel = self.panels.filter('#' + self.current_hash)

      self.tabs.attr('aria-selected', 'false')
      self.current_tab.attr('aria-selected', 'true')
      self.panels.addClass('hidden', true)
      self.current_panel.removeClass('hidden', false)

      if (history.pushState) {
        history.pushState(null, null, '#' + self.current_hash);
      }
    }
  }

  return function() {
    self.tabs.on('click', self.tab_clicked)

    if (location.hash) {
      self.tabs.filter('[aria-controls="' + location.hash.replace(new RegExp("^[#]+"), "") + '"]').click()
    }

    self.carousel = new Carousel(self, {
      slidesPerView: 2,
      spaceBetween: 0,
      centeredSlides: true,
      simulateTouch: true,
      roundLengths: true,
      slideToClickedSlide: true,
      loop: false,
      init: false,
      on: {
        slideChange: function() {
          $(self.carousel.swiper.slides[self.carousel.swiper.activeIndex]).click()
        },
      },
    }, {
      mobile: true,
      wrapper: self.find('.collections__tabs'),
      slides: self.tabs,
      onInit: function(carousel) {
        carousel.swiper.init()
        if (location.hash) {
          let index = self.tabs.filter('[aria-controls="' + location.hash.replace(new RegExp("^[#]+"), "") + '"]').index()
          carousel.swiper.slideTo(index, 0)
        }
      },
      onKill: function() {
        //
      },
    })
    self.carousel.init()
    return self
  }()
}

$.fn.Illustrations = function() {
  let self = this
  self.projector = self.find('.illustrations__projector')
  self.illustrations = self.find('.illustration')
  self.current_illustration = self.illustrations.first()
  self.carousel = undefined

  self.clicked = function(e) {
    e.preventDefault()
    self.illustrations.attr('aria-expanded', 'false')
    self.current_illustration = $(this)
    self.current_illustration.attr('aria-expanded', 'true')
    self.projector.html(self.current_illustration.find('.illustration__content').clone())
  }

  return function() {
    self.illustrations.on('click', self.clicked)
    self.current_illustration.click()

    self.carousel = new Carousel(self, {
      slidesPerView: 1.25,
      spaceBetween: 0,
      centeredSlides: true,
      simulateTouch: true,
      roundLengths: true,
      slideToClickedSlide: true,
      loop: false,
      init: false,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      on: {
        slideChange: function() {
          $(self.carousel.swiper.slides[self.carousel.swiper.activeIndex]).click()
        },
      },
    }, {
      mobile: true,
      wrapper: self.find('.illustrations__wrapper'),
      slides: self.illustrations,
      onInit: function(carousel) {
        carousel.swiper.init()
      },
      onKill: function() {
        //
      },
    })
    self.carousel.init()
    return self
  }()
}

export default {
  init() {
    $('.collections').each(function() {
      $(this).CollectionTabs()
    })

    $('.illustrations').each(function() {
      $(this).Illustrations()
    })

    /* Home page swiper */
    if ($('.collections-index').length) {
      new Swiper($('.collections-index'), {
        slidesPerView: 1,
        spaceBetween: 0,
        simulateTouch: true,
        loop: true,
        threshold: 15,
        resistanceRatio: 0.25,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
      })
    }
  },
}
