import Carousel from './carousel'

$.fn.Gallery = function() {
  let self = this

  return function() {
    /* Fancybox open */
    self.find('.gallery__item').fancybox({
      buttons: ['close'],
      transitionEffect: 'slide',
    })

    /* Video rollover */
    self.find('.gallery__item').each(function() {
      if ($(this).find('.gallery__item__video').length) {
        let pauseTimeout = undefined
        let video = $(this).find('.gallery__item__video').get(0)
        $(this).on('mouseenter', function() {
          clearTimeout(pauseTimeout)
          video.play()
        }).on('mouseleave', function() {
          pauseTimeout = setTimeout(function() {
            video.pause()
            video.currentTime = 0
          }, 300)
        })
      }
    })

    /* Mobile carousel */
    let carousel = new Carousel(self, {
      slidesPerView: 1,
      spaceBetween: 12,
      simulateTouch: true,
      loop: false,
    }, {
      mobile: true,
      desktop: false,
      wrapper: self.find('.gallery__wrapper, .grid__wrapper'),
      slides: self.find('.gallery__item, .grid__item'),
    })
    carousel.init()
    return self
  }()
}

export default {
  init() {
    $('.gallery, .grid').each(function() {
      $(this).Gallery()
    })
  },
}
